body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* .fade-animation {
  transition: all 0.5s ease-in-out;
  /* other styles for the field 
}

.fade-animation.fade-in {
  opacity: 1;
  background-color: #c3b9af;
}

.fade-animation.fade-out {
  opacity: 0;
  background-color: transparent;
}

.fade-in{
  background-color: #c3b9af;
}

.fade-out{
  background: none;
} */

:root {
  --update-color: yellow;
}

@keyframes flash {
  0%, 50%, 100% {
    /* Initial color */
    background-color: var(--update-color);
    border-bottom: 2px solid black;
  }
  25%, 75% {
    /* Changed color */
    background-color: white;
  }
}

.changed{
  /* Apply the flash animation */
  animation: flash .6s;
}

#qrReader-container video {
  z-index: 0;
  /* transform: translate(-50%, 0);  */
  /* left: 50%; */
  position: relative; 
  margin-top: -60px;
  margin-bottom: -60px;
  height: 400px;
  width: 400px;
}

#qr_rectangle {
  position: absolute;
  z-index: 1;
  width: 250px;
  height: 250px;
  border: 5px solid rgba(144, 238, 144, .6);
  border-radius: 25px;
  top: calc( 50% - 15px );
  left: 50%;
  transform: translate(-50%, -50%);
}


#qrPage {
  /* display: block;
  align-items: center; 
  justify-content: center; */
  text-align: center;
}

#mainCheckinOptions{
  display: flex; /* enable flexbox layout for the container */
  justify-content: center; /* center the columns within the contain */
}

#mainCheckinOptions > div{
  text-align: center;
  flex-grow: 1;
  font-style: italic;
}




h1{
	font-family: montserrat;
	line-height:34px;
	padding-top:20px;
	padding-bottom:20px;
	font-size: 28px;
	color:#fff;
	background-color: #c3b9af;
	text-align: center;
	text-transform:uppercase;
	letter-spacing: 3px;
	margin-bottom:30px;
	font-weight: 400;
  width: 100%;
  /* margin: auto; */
}

/* #logoImg{
  position:absolute;
  left:80px;
  top: 100px;
} */
#body{
  /* padding-bottom:250px; */
  /* height: 400px; */
  height: calc(100vh - 90px);
  overflow:scroll;
}

#footer_wrap {
  position: fixed;
  height: 50px;
  /* background-color: red; */
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin-bottom: 0px;
  margin:auto;
  padding-left: 100px;
  padding-top: 20px;
  border-top: 4px solid black;
}
#pingServer{
  z-index: 100;
    /* display: block; */
  position: relative;
}

#header h1, h2{
  margin:auto;
  text-align: center;
}

h2{
  text-transform: uppercase;
}

#home h2{
  margin: 20px auto;
}

#header{
  border-bottom: 4px solid black;
  margin:auto;
  /* margin-bottom: 20px; */
  text-align: center;;
}

#header #topbar, #footer_wrap {
  padding:20px;
  background-color: #c3b9af;
}
#body{
  /* height:calc(100%-100px); */
  margin-bottom:100px;
}
#qrPage button{
  z-index: 102;
  position:relative;
}
.event-list{
  list-style-type:none;
}
.event-list li:active, button:active{
  box-shadow: 8px 8px 0px rgba(0, 0, 0, 0.8);
  /* border: 1px solid white; */
}
.event-list li, .other-activities-area li{
  border:1px solid black;
  margin:auto;
  text-align: center;
  background-color: #6CF2BC;
  height: 42px;
  width: 65%;
  font-size: 22px;
  font-weight: 900;
  /* font-size: 20px; Set the base font size */
  /* max-width: 100%; */
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  white-space: nowrap;
  padding: 0 15px;
  line-height: 42px;
  cursor: pointer;
}
.event-list li:not(:last-child){
  margin-bottom:15px;
}
.event-list li span{
  font-size: 20px;
  margin-top:-100px;
}
body, a, p, div{
  font-family: montserrat;
}

h1.simpleHeader{
  color: black;
  background:none;
  font-size: 34px;
  margin-bottom:0;
  margin-top:20px;
  padding: 0;
}
.simpleHeader{
  margin-top:20px;
}

a button:not(.testBtn){

}
button:not(.testBtn){
  border:1px solid black;
  margin:auto;
  text-align: center;
  background-color: #6CF2BC;
  font-weight: 900;
  color: black!important;
  height: 40px;
  width: 200px;
  display:block;
  font-size: 20px;
  line-height: 40px;
  text-transform: uppercase;
  cursor: pointer;
  margin-top: 20px;
}

button.testBtn{
  margin:20px auto;
  display:block;
  font-size: 18px;
}

form input, form select{
  width: 90%;
  height: 40px;
  margin: 6px;
  text-align: center;
  font-size: 25px;
  padding: 2px 6px!important;
}
/* form select{
  height: 46px;
  width: 100%;
  margin: 2px;
} */
/* form input[type='tel']{
  width: calc(40% - 100px);
} */
form{
  margin-top: 15px;
  margin:auto;
  text-align:center;
}
h3{
  text-align: center;
}
div.message h3 span{
display: block;
font-size: 20px;
}
div.message h2 span{
  display:block;
  font-size:30px;
}
div.message{
  position: relative;
  /* top: 50%; */
  /* margin-top: 100px; */
  /* transform: translateY(50%); */
}
a button, #mainCheckinOptions a{
  text-decoration: none;
  font-weight: 900;
}

h3.redirectNotice{
  font-style:italic;
  /* position:absolute;
  bottom:120px;
  text-align:center;
  margin:auto;
  width:100%; */
}
#footer_wrap button{
  margin-top:5px;

}
h1{
  margin-bottom: 22px!important;
}
#loaderArea svg, #loaderArea p{
  font-size: 20px;
  font-style: italic;
  margin: auto;
  text-align: center;
  display: block;
  color: white;
  font-weight: 600;
}

#loaderArea {
  padding: 20px;
  margin: 20px auto;
  width: 90%;
  border-radius: 8px;
  background-color: rgba(0,0,0,.6);
}
#waiverarea{
  font-size: 12px;
  width:80%;
  margin:auto;
}
#waiverarea label{
  display:block;
  
  margin: auto;
}

button:not(.testBtn) i {
  margin-right: 10px;
}

.verticallycenter{
  margin-top: 200px;
  /* display: flex; */
  /* align-items: center; */
  height: 100vh; /* This is required for the elements to be centered within the page */
}

.centered{
  text-align:center;
}

.note{
  text-align:center;
  color: darkred;
  font-style: italic;
}
#messageWindow #messageContent{
  padding: 20px;
}
#messageWindow #messageContent{
  text-align: center;
}

.datepicker {
  width: 200px;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.datepicker:focus {
  border-color: #007bff;
  outline: none;
}

.dateinput{
  display: inline-block;
}
/* .inputwrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-template-areas:
  "fname lname email"
  "dob nationality phone gender";
  grid-gap: 20px;
}
*/
.datewrapper {
  /* grid-area: dob; */
  display:inline;
} 

.inputwrapper{
     /* padding: 4px; */
    /* margin-left: 10px; */
    text-align: center;
}
.inputwrapper input , .inputwrapper select{
  margin: 4px!important;
  display: block;
  /* width: 100%; */
  /* width: inherit!important; */
  height: 30px;
  /* margin: 3px; */
  padding: 4px!important;
  width: 100%;
}

.inputwrapper select{
  /* width: calc(100% + 12px); */
  height: 42px!important;
  /* display:block; */
  width: calc(100% + 13px);
}

#waiverarea input[type="checkbox"]{
  width: 20px;
  height: 20px;
}
#waiverarea label span{
  display: inline-block;
  /* line-height: -1px; */
  margin-top: -10px;
  vertical-align: middle;
}
#registrationForm h1.simpleHeader{
  margin-bottom: 0;
  padding-bottom: 0;
}
.event-list:has(li:nth-of-type(4)) {
  /* Apply two columns when more than three items are present */
  column-count: 2;
  column-gap: 10px; /* Adjust the gap between columns as needed */
}
.event-list:has(li:nth-of-type(4)) li{
  width: 90%;
  padding: 5px;
 /* font-size: 18px;  */
  white-space: nowrap; /* Keeps text in a single line */
  overflow: hidden; 
  /* text-overflow: ellipsis; */
  text-overflow: clip;
    /* Other styling properties */  
}
.event-list:has(li:nth-of-type(4)) li span{
  display:none!important  ;
}
.event-list:has(li:nth-of-type(4)){
  max-width: 70%;
  margin: auto;
}
.event-list:has(li:nth-of-type(4)) li span{
  clear:both;
  display: inline-block;
}

.event-list:has(li:nth-of-type(4))  li.long-text {
  white-space: normal!important;

  /* padding:0; */

  /* display: flex;  */
  align-items: center; /* Centers text vertically */
  justify-content: center;
}
.event-list li{
  /* padding: 5px 15px; */
  padding: 0px 15px;

}
#other-activities-area{
  /* padding:0 20px; */
}
#pt-list{
  width: 65%;
  display:block;
  margin: auto;
}
#other-activities-area li{
  /* height: 43px!important; */
  /* line-height: 43px; */
  /* font-size: 26px; */
  /* background-color: #333; */
  /* color: yellow; */
  /* border-radius: 10px; */
  margin-bottom: 10px;
  padding: 5px 15px;
  background-color: #ddf293;
}
#other-activities-list li{
  /* width:95%; */
  /* background-color: rgba(0,0,0,.8);
  background-color: yellow; */
}
/* This will create two columns inside the #other-activities-area */
#other-activities-area {
  /* display: flex;
  flex-wrap: wrap; */
  /* justify-content: space-between; This will space the two children evenly */
}

#other-activities-area > div {
  flex-basis: calc(50% - 10px); /* Assumes a 10px gap between two children */
  /* If you have padding or border, consider using box-sizing: border-box; */
  box-sizing: border-box;
}

/* This will space out the list items in #pt-list evenly across the full width */
#pt-list {
  display: flex;
  justify-content: space-between; /* This will space the list items evenly */
  padding: 0; /* Remove padding if it's a list */
  list-style-type: none; /* Remdove bullets if it's a list */
  width: calc(65% + 60px);
}
.event-list{
  padding: 0;
}

#pt-list > li {
  text-align: center;
  width: calc(33% - 50px);
}
#calendar-event-list li:active{
  color: black;
  background-color: yellow;
}
#other-activities-area .event-list li:active, button:active{
  /* box-shadow: 8px 8px 0px rgba(0, 0, 0, 0.8); */
  border: 1px solid white;
}
#checkins-container {
  
}
.flushtop{
  margin-top: -20px;
}
#calendar-event-list:empty {
  margin-top: 20px;
}

.spacer{
  margin-top:30px;
}
#message-area {
  height: 100px;
  display: block;
  width: 400px;
  position: absolute;
  right: 0;
  top: 0;
  line-height: 100px;
  font-size: 22px;
}

#center-content{
  /* background-color:rgba(0,255,0,.2); */
  /* padding-top: 10px; */
    /* height: 100vw; */
  height: calc(100vh - 196px);
  position: absolute;
  left: 50%;
  top: 50%; /* If you also want to center it vertically within the parent */
  transform: translate(-50%, -50%); /* Adjusts for the element's own width and height */
  
  /* Flexbox to center content vertically */
  display: flex;
  flex-direction: column;
  align-items: center; /* Horizontally centers the content in a flex container with column direction */
  justify-content: center; /* Vertically centers the content */
  width: 100%;
}


#checkins-container {
  width: 100%;
}
#loaderArea svg{
  margin-bottom:20px;
}
p{
  text-align:center;
}
#center-content > div:not(#loaderArea) {
  width: 100%;
  max-height: calc(100vh - 196px);
}

#colreg {
  /* background-color: green; */
  display: grid;
  grid-template-columns: 30% 70%; /* First column is 40% of width, second is 60% */
  
}
a:has(> button) {
 text-decoration: none;
 color:black;
}
#header #logoImg, #footer_wrap #logoImg{
  height: 60px;
  width: auto;
}
#footer_wrap #logoImg{
  margin-top: -5px;
}
#checkins-container h2{
  margin-bottom: 15px;
  margin-top: 25px;
}
.usercredit{
  right: 0;
  position: absolute;
  margin-right:30px;
  margin-top: 25px;
}
.paymentarea{
  border: 1px solid black;
  border-radius: 10px;
  width: 90%;
  margin:auto;
}
.invoice-container p{
  text-align:left;
}


.invoice-container{
  width: 90%;
  padding: 20px;
  /* border:2px solid black; */
  margin:auto;
}
.invoice-container .payment-details table{
  width:100%;
}
.invoice-header {
  display: flex; /* Enables Flexbox */
  justify-content: space-between; /* Distributes space between the child elements */
}

.invoice-header > div {
  flex: 1; /* Each child div will take up equal space */
}
.invoice-header .companyname{
  font-weight:900;
  margin-bottom:0;
}
.invoice-header .address{
  margin-top:5px;
}
div.companydetails, div.companydetails h3{
  text-align: right;
}
div.invoicedetails h3{
  text-align: left;
}
.invoice-header span, .customerdetails span{
  font-weight:900;
}
.invoiceFooter {
  margin-top:50px;
  text-align: center;
}
.invoiceFooter:before {
  width: 600px;
  height: 3px;
  background-color: black;
  content: '';
  display: block;
  border-radius: 5px;
  margin:auto;
  margin-bottom:30px;
}
.invoice-title-area{

}
.invoice-header{
  margin-top: 80px;
}
.invoice-buttons{
  float:right;
  text-align:right;
  margin-right: 50px;
  /* margin-bottom:10px; */
}
.invoice-buttons button{
  display:inline-block;
  margin:10px;
  line-height: 27px;
  height: 30px;
  width: 30px;
}
.invoice-buttons button svg{
  font-size: 20px;
  margin-top: -10px;
}
button.email svg{
  margin-left: -2px;
}

.logoimg{
  /* width: 300px; */
  text-align: right;
  /* float: right; */
}
.invoicetitle{
text-align: left;
float: left;
  /* margin-left: 300px; */
  /* width: calc(100% - 350px); */
  /* float:left; */
}
.invoice-container h3{
  text-align: left;
  margin-top: 50px;
}
.companydetails p{
  text-align:right;
}
.paidAmt {
  display: block;
  padding: 2px;
  margin: 5px 0px 0px 0px;
  font-size: 14px;
}
.paypal-link, .paypal-link a{
  text-align: center;
  margin: auto;
}
.paypal-link {
  text-align: center;
  margin-top: 20px;
}

.paypal-button {
  line-height: 30px;
  display: inline-block;
  background-color: #ffc439;
  color: #000;
  text-decoration: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-weight: bold;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
}

.paypal-button:hover, .paypal-button:focus {
  background-color: #f9b51b;
  color: #000;
  text-decoration: none;
}

.paypal-button:active {
  background-color: #f7a600;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2) inset;
}
.paypal-link svg {
  color: blue;
  font-size: 30px;
}
.logoimg, .logoimg  img{
  height: 80px;
  /* position:absolute; */
  /* float:right; */
  /* right:0; */
}
.invoicetotal {
  /* float: right; */
  width: 100%;
  text-align: right;
  font-size: 20px;
}
.invoicetotal h2 {
  /* border-top: 1px solid black; */
  text-align: right;
  padding-top: 20px;
  margin-bottom: 0;
  text-decoration: underline;
}
.invoice-container .payment-details table {
  width: 100%;
}
table th {
  text-align: left;
}

th {
  background-color: rgb(195, 185, 175);
  height: 20px;
  padding: 10px;
  font-size: 15px!important;
}
.payment-details td {
  padding: 8px;
  border: 1px solid #ccc;
}
table {
  border-collapse: collapse;
  margin-bottom: 10px;
}
@media print {
  .paypal-button {
    line-height: 400px;
  }
  .invoicetitle h2 {
    color: green;
  }
  body {
    margin: 0;
    padding: 0;
    box-shadow: none;
  }

  #invoice-container {
    width: 210mm;
    min-height: 297mm;
    margin: 10mm auto; /* Adjust as needed */
    box-shadow: none;
    page-break-after: always;
    border: none!important;
  }

  .invoicetitle{
    float: left;
    text-align: left;
    margin-left: 0px!important;
    /* margin-top:300px; */
  } 

  /* Additional print styles here */
  /* Hide elements not needed for print */
  .notprintable {
    display: none!important;
  }
  
  .printonly{
    display:block!important;
  }
  .invoice-title-area{
    text-align: center;
    display:block;
  }
}
.printonly{
  display:none;
}
#invoicePayTop div{
  width:40%;
  display: inline-block;
  float: left;
}

.paypal-buttons{
  width: 300px!important;
}
.invoice-page {
  display: flex;
  width: calc(95% - 20px);
  gap: 30px;
  padding: 10px;
  margin:auto;
  border-radius: 10px;
  margin-bottom: 10px;
}

.invoice-page > div {
  flex: 1; /* Each child div takes up equal space, resulting in 50% width each */
}


.two-columns {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.inputwrapper {
  flex: 1 1 40%; /* flex-grow, flex-shrink, and flex-basis */
  box-sizing: border-box; /* Include padding and borders in the element's total width and height */
}

#waiverarea {
  width: 100%;
  margin-top: 20px;
}
.invoice-page{
  border:2px solid black;
}
div#payButtonContainer {
  padding-top: 20px;
}
.paymentsuccess{
  background: yellowgreen;
  font-size: 25px;
  padding: 20px;
  text-align: center!important;
}

.input-error{
  border-color: red;
}
label:has(.input-error){
  border: 1px solid red;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 20px);
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5); /* White background with opacity */
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 10;
}

.overlay p{
  text-align: center;
  color: red;
  font-style: italic;
  font-weight: 600;
}
.overlay {
  padding: 10px;
}